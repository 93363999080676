<template>

  <div>
    <b-modal
      :ref="id"
      :title="title"
    >
      <b-col>
        <b-table
          :fields="fields"
          :items="items"
          striped
          hover
          responsive
          class="data-table"
        >
          <template v-slot:cell(user_id)="data">
            <b-link
              :href="'client-single/'+ data.item.user_id"
            >
              {{ data.item.user_id }}
            </b-link>
          </template>
        </b-table>
      </b-col>
    </b-modal>

    <div>
      <div class="table__header">
        <h4>{{ title }}</h4>
        <button
          id="toggle-btn"
          @click="toggleModal(id)"
        >
          VER TODOS
        </button>
      </div>
      <b-table
        :fields="fields"
        :items="items.slice(0, 5)"
        hover
        responsive
        class="data-table"
      >
        <template v-slot:cell(user_id)="data">
          <b-link
            :href="'client-single/'+ data.item.user_id"
          >
            {{ data.item.user_id }}
          </b-link>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BCol, BTable, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BTable,
    BLink,
  },
  props: ['title', 'id', 'fields', 'items'],
  data() {
    return {}
  },
  mounted() {
  },
  created() {

  },
  methods: {
    toggleModal(id) {
      this.$refs[id].toggle('#toggle-btn')
    },

  },
}
</script>

  <style lang="scss">
    .table__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    #toggle-btn {
      background: transparent;
      border: none;
      color: #7367f0;
      &:hover {
        color:#4D43C0;
      }
    }
  </style>
