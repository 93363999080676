<template>
  <b-card
    class="stat-card text-center"
  >
    <div class="card-up">
      <b-card-title class="text-center">
        {{ cardHeaderTitle }}
      </b-card-title>
      <div class="main-number mb-1">
        {{ cardHeaderValue }}
      </div>
      <ComparisonPercentage :percentage="cardHeaderPercentage" />
    </div>

    <div class="divisor" />

    <div class="card-down">
      <b-card-title class="text-center">
        {{ cardFooterTitle }}
      </b-card-title>
      <div class="main-number mb-1">
        {{ cardFooterValue }}
      </div>

      <ComparisonPercentage :percentage="cardFooterPercentage" />

    </div>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BCardText, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import ComparisonPercentage from './ComparisonPercentage.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    ComparisonPercentage,
  },
  props: ['cardHeaderTitle', 'cardHeaderValue', 'cardHeaderPercentage', 'cardFooterTitle', 'cardFooterValue', 'cardFooterPercentage'],
  data() {
    return {
    }
  },
  methods: {

  },
}
</script>

  <style lang="scss">
  .stat-card{
    .main-number{
        font-size: 25px;
        text-align: center;
    }
    .indicator{
        text-align: center;
    }
    .card-title{
        margin-bottom:10px;
    }
    .divisor{
        width: 90%;
        height: 1px;
        background-color: #4D4D66;
        margin: 20px auto;
        opacity: 0.4;
    }
  }
  </style>
