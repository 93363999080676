<template>
  <b-card
    v-if="true"
    class="stat-card"
  >
    <div>
      <b-tabs>
        <b-tab
          title="Ganhos"
          active
        >
          <b-row>
            <b-col class="m-2">
              <div class="card-up text-center">
                <b-card-title>
                  Volume Total
                </b-card-title>
                <div class="main-number mb-1">
                  {{ formattedCurrency(depositsTransactionsData.transactionCounts) }}
                </div>
                <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.transactionCounts" /></div>
              <div class="divisor" />
              <div class="card-down text-center">
                <b-card-title>
                  Média por usuário
                </b-card-title>
                <div class="main-number mb-1">
                  {{ formattedCurrency(depositsTransactionsData.averageAmount) }}

                </div>
                <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.averageAmount" /></div>
            </b-col>
            <b-col>
              <ReportTable
                id="bigger"
                title="Maiores"
                :fields="fields"
                :items="depositsTransactionsData.tableItems.biggestTransactions"
              />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Perdas"
        >
          <b-row>
            <b-col class="m-2">
              <div class="card-up text-center">
                <b-card-title>
                  Volume Total
                </b-card-title>
                <div class="main-number mb-1">
                  {{ formattedCurrency(withdrawalsTransactionsData.transactionCounts) }}
                </div>
                <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.transactionCounts" /></div>
              <div class="divisor" />
              <div class="card-down text-center">
                <b-card-title>
                  Média por usuário
                </b-card-title>
                <div class="main-number mb-1">
                  {{ formattedCurrency(withdrawalsTransactionsData.averageAmount) }}

                </div>
                <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.averageAmount" /></div>
            </b-col>
            <b-col>
              <ReportTable
                id="bigger"
                title="Maiores"
                :fields="fields"
                :items="withdrawalsTransactionsData.tableItems.biggestTransactions"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BTab, BTabs,
} from 'bootstrap-vue'
import { reportTablesFields } from '@/consts/reportTablesFields'
import ReportTable from './ReportTable.vue'
import ComparisonPercentage from './ComparisonPercentage.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardTitle,
    ReportTable,
    BTab,
    BTabs,
    ComparisonPercentage,
  },
  props: ['depositsTransactionsData', 'withdrawalsTransactionsData', 'formattedCurrency'],
  data() {
    return {
      fields: reportTablesFields.biggestValues,
    }
  },
  mounted() {
  },
  created() {

  },
  methods: {

  },
}
</script>

  <style lang="scss">

  </style>
