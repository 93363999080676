<template>
  <b-card
    v-if="true"
    class="stat-card"
  >
    <b-card-title class="text-center">
      Ativos por Estado
    </b-card-title>
    <div id="container" />
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
  },
  props: ['usersByState'],
  data() {
    return {}
  },
  async created() {
    const topology = await fetch(
      'https://code.highcharts.com/mapdata/countries/br/br-all.topo.json',
    ).then(response => response.json())

    const data = this.usersByState
    // eslint-disable-next-line no-undef
    Highcharts.mapChart('container', {

      chart: {
        map: topology,
        backgroundColor: 'transparent',
      },

      title: {
        text: '',
      },
      tooltip: {
        backgroundColor: 'none',
        borderWidth: 0,
        shadow: false,
        useHTML: true,
        padding: 0,
        pointFormat: '<span>{point.name}</span><br>'
            + '<span style="font-size:30px">{point.value}</span>',
        positioner() {
          return { x: 0, y: 250 }
        },
      },

      colorAxis: {
        min: 1,
        max: 1000,
        type: 'logarithmic',
      },

      series: [{
        data,
        name: 'Ativos por Estado',
        states: {
          hover: {
            color: '#4D43C0',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
      }],
    })
  },
  methods: {

  },
}
</script>

  <style lang="scss">
#container {
    height: 500px;
    max-width: 800px;
    margin: 0 auto;
}

.highcharts-tooltip > span {
    padding: 10px;
    white-space: normal !important;
    width: 200px;
    color: #d0d2d6 !important;
}

.loading {
    margin-top: 10em;
    text-align: center;
    color: gray;
}

.f32 .flag {
    vertical-align: middle !important;
}

  </style>
