<template>

  <b-card
    v-if="true"
    class="stat-card"
  >
    <b-tabs>
      <b-tab
        title="Cash In"
      >
        <div>
          <b-row class="d-flex justify-content-start mb-3">
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Total de Depósitos
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ formattedCurrency(depositsTransactionsData.transactionCounts) }}</span>
              </h4>
              <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.transactionCounts" />
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Depositantes
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ depositsTransactionsData.userPerTransactionLength }}</span>
              </h4>
              <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.userPerTransactionLength" />

            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Ticket Médio
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ formattedCurrency(depositsTransactionsData.averageAmount) }}</span>
              </h4>
              <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.averageAmount" />

            </b-col>
            <b-col
              md="6"
              lg="3"
              class="align-start "
            >
              <b-card-text class="mb-50">
                # Depósitos
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary"> {{ depositsTransactionsData.numberAmount }}</span>
              </h4>
              <ComparisonPercentage :percentage="depositsTransactionsData.percentageComparison.numberAmount" />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="lastDeposits"
                title="Ultimos Depósitos"
                :fields="fields.lastDeposits"
                :items="depositsTransactionsData.tableItems.lastTransactions"
              />
            </b-col>
            <b-col class="mb-2">
              <ReportTable
                id="largestDeposits"
                title="Maiores Depósitos"
                :fields="fields.biggestDeposits"
                :items="depositsTransactionsData.tableItems.biggestTransactions"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="LargestNumDeposits"
                title="Maior N° de Depósitos"
                :fields="fields.biggestQuantityDeposits"
                :items="depositsTransactionsData.tableItems.highestQuantity"
              />
            </b-col>
            <b-col class="mb-2">
              <ReportTable
                id="highestFrequency"
                title="Maior Frequência"
                :fields="fields.mostFrequentUsers"
                :items="depositsTransactionsData.tableItems.transactionsQuantity"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="withoutDeposits"
                title="Sem Depósitos desde"
                :fields="fields.usersWithoutDeposits"
                :items="depositsTransactionsData.tableItems.longerTimesWithoutTransactions"
              />
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <b-tab
        title="Cash Out"
      >
        <div>
          <b-row class="d-flex justify-content-start mb-3">
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Total de Depósitos
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ formattedCurrency(withdrawalsTransactionsData.transactionCounts) }}</span>
              </h4>
              <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.transactionCounts" />
            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Depositantes
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ withdrawalsTransactionsData.userPerTransactionLength }}</span>
              </h4>
              <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.userPerTransactionLength" />

            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                Ticket Médio
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary">{{ formattedCurrency(withdrawalsTransactionsData.averageAmount) }}</span>

              </h4>
              <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.averageAmount" />

            </b-col>
            <b-col
              md="6"
              lg="3"
            >
              <b-card-text class="mb-50">
                # Depósitos
              </b-card-text>
              <h4 class="font-weight-bolder">
                <span class="text-primary"> {{ withdrawalsTransactionsData.numberAmount }}</span>
              </h4>
              <ComparisonPercentage :percentage="withdrawalsTransactionsData.percentageComparison.numberAmount" />

            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="lastDeposits"
                title="Ultimos Depósitos"
                :fields="fields.lastDeposits"
                :items="withdrawalsTransactionsData.tableItems.lastTransactions"
              />
            </b-col>
            <b-col class="mb-2">
              <ReportTable
                id="largestDeposits"
                title="Maiores Depósitos"
                :fields="fields.biggestDeposits"
                :items="withdrawalsTransactionsData.tableItems.biggestTransactions"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="LargestNumDeposits"
                title="Maior N° de Depósitos"
                :fields="fields.biggestQuantityDeposits"
                :items="withdrawalsTransactionsData.tableItems.highestQuantity"
              />
            </b-col>
            <b-col class="mb-2">
              <ReportTable
                id="highestFrequency"
                title="Maior Frequência"
                :fields="fields.mostFrequentUsers"
                :items="withdrawalsTransactionsData.tableItems.transactionsQuantity"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-2">
              <ReportTable
                id="withoutDeposits"
                title="Sem Depósitos desde"
                :fields="fields.usersWithoutDeposits"
                :items="withdrawalsTransactionsData.tableItems.longerTimesWithoutTransactions"
              />
            </b-col>
          </b-row>
        </div>
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BTab, BTabs,
} from 'bootstrap-vue'
import ReportTable from './ReportTable.vue'
import { reportTablesFields } from '../../consts/reportTablesFields'
import ComparisonPercentage from './ComparisonPercentage.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardText,
    ReportTable,
    BTab,
    BTabs,
    ComparisonPercentage,
  },
  props: ['depositsTransactionsData', 'withdrawalsTransactionsData', 'formattedCurrency'],
  data() {
    return {
      fields: reportTablesFields,
    }
  },
}
</script>

<style lang="scss">
.align-start {
  text-align: start !important;
}
.table__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
