<template>
  <b-card
    v-if="true"
    class="stat-card"
  >
    <b-card no-body>
      <b-card-body class="pb-0">
        <b-row class="d-flex justify-content-start mb-3">
          <b-col
            lg="3"
            md="6"
          >
            <b-card-text>
              Usuários Ativos
            </b-card-text>
            <h3 class="font-weight-bolder">
              <span class="text-primary">{{ activeUsers.activeUsers }}</span>
            </h3>
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <b-card-text>
              FTDS #
            </b-card-text>
            <h3 class="font-weight-bolder">
              <span class="text-primary">{{ userData.usersByDeposits.length }}</span>
            </h3>
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <b-card-text>
              Novos Depósitos
            </b-card-text>
            <h3 class="font-weight-bolder">
              <span class="text-primary">{{ formattedCurrency(depositsTransactionsData.transactionCounts) }}</span>
            </h3>
          </b-col>
          <b-col
            lg="3"
            md="6"
          >
            <b-card-text>
              Depósito Médio
            </b-card-text>
            <h3 class="font-weight-bolder">
              <span class="text-primary">{{ formattedCurrency(depositsTransactionsData.averageAmount) }}</span>
            </h3>
          </b-col>
        </b-row>
        <vue-apex-charts
          type="line"
          height="240"
          :options="chartOptions"
          :series="series"
        />
      </b-card-body>
    </b-card>
  </b-card>
</template>
<script>
import {
  BCard, BRow, BCol, BCardText, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardText,
    BCardBody,
    VueApexCharts,
  },
  props: ['depositsTransactionsData', 'withdrawalsTransactionsData', 'userData', 'activeUsers', 'formattedCurrency'],
  data() {
    return {
      series: [
        {
          name: 'Cash In',
          data: Object.values(this.depositsTransactionsData.amountTransactionCountByDay),
        },
        {
          name: 'Cash Out',
          data: Object.values(this.withdrawalsTransactionsData.amountTransactionCountByDay),
        },
      ],
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          dashArray: [0, 12],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#d0ccff', '#ebe9f1'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: [$themeColors.primary, '#ebe9f1'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: Object.keys(this.depositsTransactionsData.amountTransactionCountByDay),
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },

    }
  },

  methods: {

  },
}
</script>

  <style lang="scss">

  </style>
