export const stateByDDD = {
  11: 'br-sp',
  12: 'br-sp',
  13: 'br-sp',
  14: 'br-sp',
  15: 'br-sp',
  16: 'br-sp',
  17: 'br-sp',
  18: 'br-sp',
  19: 'br-sp',
  21: 'br-rj',
  22: 'br-rj',
  24: 'br-rj',
  27: 'br-es',
  28: 'br-es',
  31: 'br-mg',
  32: 'br-mg',
  33: 'br-mg',
  34: 'br-mg',
  35: 'br-mg',
  37: 'br-mg',
  38: 'br-mg',
  41: 'br-pr',
  42: 'br-pr',
  43: 'br-pr',
  44: 'br-pr',
  45: 'br-pr',
  46: 'br-pr',
  47: 'br-sc',
  48: 'br-sc',
  49: 'br-sc',
  51: 'br-rs',
  53: 'br-rs',
  54: 'br-rs',
  55: 'br-rs',
  61: 'br-df',
  62: 'br-go',
  63: 'br-to',
  64: 'br-go',
  65: 'br-mt',
  66: 'br-mt',
  67: 'br-ms',
  68: 'br-ac',
  69: 'br-ro',
  71: 'br-ba',
  73: 'br-ba',
  74: 'br-ba',
  75: 'br-ba',
  77: 'br-ba',
  79: 'br-se',
  81: 'br-pe',
  82: 'br-al',
  83: 'br-pb',
  84: 'br-rn',
  85: 'br-ce',
  86: 'br-pi',
  87: 'br-pe',
  88: 'br-ce',
  89: 'br-pi',
  91: 'br-pa',
  92: 'br-am',
  93: 'br-pa',
  94: 'br-pa',
  95: 'br-rr',
  96: 'br-ap',
  97: 'br-am',
  98: 'br-ma',
  99: 'br-ma',
}
