<template>
  <b-card
    class="stats-pending-card "
  >
    <div class="stats-pending-body">
      <b-card-title class="text-center">
        Usuários com Saques acima de R$ 10.000
      </b-card-title>
      <div class="main-number">
        {{ cardValue }}
      </div>

      <ComparisonPercentage :percentage="cardPercentage" />
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import ComparisonPercentage from './ComparisonPercentage.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    ComparisonPercentage,
  },
  props: ['cardValue', 'cardPercentage'],
  data() {
    return {
    }
  },
  methods: {

  },
}
</script>

  <style lang="scss">
  .stats-pending-card{
    height: 90%;
    .stats-pending-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .main-number{
        font-size: 25px;
        text-align: center;
    }
    .indicator{
        text-align: center;
    }
    .card-title{
        margin-bottom:10px;
    }
    .divisor{
        width: 90%;
        height: 1px;
        background-color: ghostwhite;
        margin: 20px auto;
        opacity: 0.4;
    }
  }
  </style>
