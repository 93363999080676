import { stateByDDD } from '@/consts/stateByDDD'
import dayjs from 'dayjs'

function countUsersByDeposits(usersByDate) {
  const depositCounts = Array.from({ length: 5 }, () => ({ qtyWithoutSum: 0 }))
  let sum = 0

  const usersByDeposits = usersByDate
    .filter(user => user.documents.some(doc => doc.type === 'DEPOSIT_COUNT'))
    .map(user => {
      const depositDocument = user.documents.find(doc => doc.type === 'DEPOSIT_COUNT')
      const depositCount = parseInt(depositDocument.value, 10)
      let idx = 0;
      if (!Number.isNaN(depositCount) && depositCount > 0) {
        if(depositCount > 4){
          idx=4;
        }else{
          idx = depositCount;
        }
        depositCounts[idx].qtyWithoutSum += 1
      }

      return user
    })

  for (let i = depositCounts.length - 1; i > 0; i--) {
    const count = depositCounts[i]
    sum += count.qtyWithoutSum
    count.quantity = sum
    count.percentage = ((count.qtyWithoutSum / usersByDeposits.length) * 100).toFixed(1)
    count.totalPercentage = ((count.qtyWithoutSum / usersByDate.length) * 100).toFixed(1)
  }

  return { depositCounts, usersByDeposits }
}

function countUsersByState(usersByDate) {
  const countByState = new Map()

  usersByDate.forEach(({ username }) => {
    const ddd = username.substring(3, 5)
    const state = stateByDDD[ddd]

    countByState.set(state, (countByState.get(state) || 0) + 1)
  })

  const usersByState = Array.from(countByState.entries())

  return usersByState
}

function countTransactions(transactionData) {
  let transactionCounts = 0
  let usersLargeTransactionCount = 0
  const userPerTransaction = []
  const amountTransactionCountByDay = {}

  transactionData.forEach(transaction => {
    transactionCounts += transaction.amount
    if (transaction.amount > 10000) {
      usersLargeTransactionCount += 1
    }

    const transactionDate = dayjs(transaction.created_at)

    if (transactionDate.hour() >= 3) {
      const date = transactionDate.format('YYYY-MM-DD')
      amountTransactionCountByDay[date] = (amountTransactionCountByDay[date] || 0) + transaction.amount
    } else if (transactionDate.hour() < 3) {
      const date = transactionDate.subtract(1, 'day').format('YYYY-MM-DD')
      amountTransactionCountByDay[date] = (amountTransactionCountByDay[date] || 0) + transaction.amount
    }

    const { user_id } = transaction

    const existingUserIndex = userPerTransaction.findIndex(user => user.user_id === user_id)

    if (existingUserIndex === -1) {
      userPerTransaction.push({ user_id, quantity: 0, totalAmount: 0 })
    }

    const currentUser = userPerTransaction.find(user => user.user_id === user_id)

    currentUser.quantity += 1
    currentUser.totalAmount += transaction.amount
  })
  const ordenedKey = Object.keys(amountTransactionCountByDay).sort()
  const amountTransactionCountByDayOrdened = {}
  ordenedKey.forEach(key => {
    amountTransactionCountByDayOrdened[key] = amountTransactionCountByDay[key]
  })

  return {
    transactionCounts,
    usersLargeTransactionCount,
    userPerTransaction,
    amountTransactionCountByDay: amountTransactionCountByDayOrdened,
    userPerTransactionLength: userPerTransaction.length,
    numberAmount: transactionData.length,
    averageAmount: transactionCounts / transactionData.length,
  }
}

function getTablesItems(transactionByDate) {
  const formatTransaction = transaction => ({
    ...transaction,
    time: transaction.created_at.split(' ')[1],
  })

  const sortByDateDesc = (a, b) => new Date(b.created_at) - new Date(a.created_at)
  const sortByAmountDesc = (a, b) => b.amount - a.amount
  const sortByLastTransactionDateAsc = (a, b) => new Date(a.lastTransactionDate) - new Date(b.lastTransactionDate)

  const lastTransactions = transactionByDate.map(formatTransaction).sort(sortByDateDesc)
  const biggestTransactions = [...lastTransactions].sort(sortByAmountDesc)

  const transactionsQuantity = lastTransactions.reduce((acc, order) => {
    const { user_id, amount, created_at } = order

    let user = acc.find(u => u.user_id === user_id)

    if (!user) {
      user = {
        user_id,
        quantity: 0,
        totalAmount: 0,
        lastTransaction: 0,
        lastTransactionDate: '',
      }
      acc.push(user)
    }

    user.quantity++
    user.totalAmount += amount

    if (created_at > user.lastTransactionDate) {
      user.lastTransaction = amount
      user.lastTransactionDate = created_at
    }

    return acc
  }, [])

  const highestQuantity = transactionsQuantity.reduce((acc, transaction) => {
    const { quantity, totalAmount } = transaction

    if (!acc[quantity]) {
      acc[quantity] = {
        quantity,
        totalUsers: 0,
        totalAmount: 0,
      }
    }

    acc[quantity].totalUsers += 1
    acc[quantity].totalAmount += totalAmount

    return acc
  }, {})

  const longerTimesWithoutTransactions = [...transactionsQuantity].sort(sortByLastTransactionDateAsc)

  return {
    lastTransactions,
    biggestTransactions,
    transactionsQuantity,
    highestQuantity: Object.values(highestQuantity),
    longerTimesWithoutTransactions,
  }
}

function calculatePercentage(currentValue, oldValue) {
  if (oldValue === 0) return 0
  if (currentValue === 0) return 0

  return ((currentValue - oldValue) / oldValue) * 100
}

function getUserPercentageComparison(registeredCPFs, registeredCPFsComparison, depositCounts, depositCountsComparison) {
  const comparisonDeposits = {}

  const commonKeys = Object.keys(depositCountsComparison).filter(key => depositCounts[key])

  commonKeys.forEach(key => {
    const percentageChange = calculatePercentage(depositCountsComparison[key].quantity, depositCounts[key].quantity)

    comparisonDeposits[key] = percentageChange
  })

  return {
    registeredCPFs: calculatePercentage(registeredCPFs, registeredCPFsComparison),
    depositCounts: comparisonDeposits,
  }
}

function getTransactionsPercentageComparison(transactionSummary, transactionSummaryComparison) {
  const fieldsToCompare = ['transactionCounts', 'userPerTransactionLength', 'usersLargeTransactionCount', 'numberAmount', 'averageAmount']

  return fieldsToCompare.reduce((comparison, field) => {
    // eslint-disable-next-line no-param-reassign
    comparison[field] = calculatePercentage(transactionSummary[field], transactionSummaryComparison[field])
    return comparison
  }, {})
}

function getUniqueUserIds(userPerDeposit, userPerWithdraw) {
  const uniqueUserIds = [...new Set([...userPerDeposit.map(t => t.user_id), ...userPerWithdraw.map(t => t.user_id)])]

  const mergedArray = uniqueUserIds.map(user_id => ({
    user_id,
  }))

  return mergedArray.length
}

function getActiveUsers(depositsData, depositsDataComparison, withdrawalsData, withdrawalsDataComparison) {
  const activeUsers = getUniqueUserIds(depositsData.userPerTransaction, withdrawalsData.userPerTransaction)
  const currentActiveUsersComparison = getUniqueUserIds(depositsDataComparison, withdrawalsDataComparison)
  const activeUsersComparison = calculatePercentage(activeUsers, currentActiveUsersComparison)

  return { activeUsers, activeUsersComparison }
}

function getBalanceGGR(depositsData, withdrawalsData) {
  const balance = depositsData.transactionCounts - withdrawalsData.transactionCounts
  const balanceComparisonData = depositsData.transactionSummaryComparison.transactionCounts - withdrawalsData.transactionSummaryComparison.transactionCounts
  const balancePercentage = balance === 0 || withdrawalsData.transactionCounts === 0 ? 0 : (balance / withdrawalsData.transactionCounts) * 100
  const balancePercentageComparisonData = balanceComparisonData === 0 || withdrawalsData.transactionCounts === 0 ? 0 : (balanceComparisonData / depositsData.transactionSummaryComparison.transactionCounts) * 100
  const balanceComparison = calculatePercentage(balance, balanceComparisonData)
  const balancePercentageComparison = calculatePercentage(balancePercentage, balancePercentageComparisonData)

  return {
    balance, balanceComparison, balancePercentage, balancePercentageComparison,
  }
}
function getDataFilteredByUserRole(apiData, userInfo) {
  const usersData = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[0].data.usersByDates.filter(user => user.documents.some(doc => doc.type === 'CLICKID' && doc.value === userInfo.inviteCode))
    : apiData[0].data.usersByDates

  const usersDataComparison = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[1].data.usersByDates.filter(user => user.documents.some(doc => doc.type === 'CLICKID' && doc.value === userInfo.inviteCode))
    : apiData[1].data.usersByDates

  const userIds = usersData.map(user => user.id)
  const userComparisonIds = usersDataComparison.map(user => user.id)

  const depositsData = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[2].data.ordersByDates.filter(order => userIds.includes(order.user_id)) : apiData[2].data.ordersByDates
  const depositsDataComparison = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[3].data.ordersByDates.filter(order => userComparisonIds.includes(order.user_id)) : apiData[3].data.ordersByDates

  const withdrawalsData = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[4].data.ordersByDates.filter(order => userIds.includes(order.user_id)) : apiData[4].data.ordersByDates
  const withdrawalsDataComparison = userInfo.userRole === 'sales' || userInfo.userRole === 'mediapartner' ? apiData[5].data.ordersByDates.filter(order => userComparisonIds.includes(order.user_id)) : apiData[5].data.ordersByDates

  return {
    usersData, usersDataComparison, depositsData, depositsDataComparison, withdrawalsData, withdrawalsDataComparison,
  }
}

function getUserData(usersData, usersDataComparison) {
  const usersCounters = countUsersByDeposits(usersData)
  const usersCountersComparison = countUsersByDeposits(usersDataComparison)
  const usersByState = countUsersByState(usersData)
  const percentageComparison = getUserPercentageComparison(usersData.length, usersDataComparison.length, usersCounters.depositCounts, usersCountersComparison.depositCounts)

  return {
    usersByState, percentageComparison, registeredCPFs: usersData.length, ...usersCounters,
  }
}

function getTransactionsData(transactionData, transactionDataComparison) {
  const tableItems = getTablesItems(transactionData)
  const transactionSummary = countTransactions(transactionData)
  const transactionSummaryComparison = countTransactions(transactionDataComparison)
  const percentageComparison = getTransactionsPercentageComparison(transactionSummary, transactionSummaryComparison)

  return {
    ...transactionSummary, transactionSummaryComparison, tableItems, percentageComparison,
  }
}

export {
  getUserData, getTransactionsData, getActiveUsers, getBalanceGGR, getDataFilteredByUserRole,
}
