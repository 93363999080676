const lastDeposits = [
  { key: 'time', label: 'Time' },
  { key: 'id', label: 'ID' },
  { key: 'user_id', label: 'Usuário' },
  { key: 'amount', label: 'Valor' },
]

const biggestDeposits = [
  { key: 'id', label: 'ID' },
  { key: 'user_id', label: 'Usuário' },
  { key: 'amount', label: 'Valor' },
]

const biggestQuantityDeposits = [
  { key: 'quantity', label: 'DEPÓSITOS' },
  { key: 'totalUsers', label: 'N° DE USUÁRIOS' },
  { key: 'totalAmount', label: 'Valor' },
]

const mostFrequentUsers = [
  { key: 'quantity', label: 'DEPÓSITOS' },
  { key: 'user_id', label: 'Usuário' },
  { key: 'totalAmount', label: 'Valor' },
]

const usersWithoutDeposits = [
  { key: 'user_id', label: 'Usuário' },
  { key: 'quantity', label: 'Total Depósitos' },
  { key: 'totalAmount', label: 'Valor' },
  { key: 'lastTransactionDate', label: 'Data Último' },
  { key: 'lastTransaction', label: 'último Depósito' },
]

const biggestValues = [{ key: 'id', label: 'ID' }, { key: 'user_id', label: 'Usuário' }, { key: 'amount', label: 'Valor' }]

export const reportTablesFields = {
  lastDeposits, biggestDeposits, biggestQuantityDeposits, mostFrequentUsers, usersWithoutDeposits, biggestValues,
}
