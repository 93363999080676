import { gql } from 'apollo-boost'
import { cashioClient, identityClient } from '@/main'

const requests = {
  getUsersByDates: (startDate, endDate) => identityClient.query({
    query: gql`
      query($startDate: String!, $endDate: String!){
        usersByDates(startDate: $startDate, endDate: $endDate) {
          id
          username
          created_at
          documents{
            type
            value
          }
        }
      }`,
    variables: { startDate, endDate },
  }),
  getOrdersByDates: (startDate, endDate, status = 'CONFIRMED', orderType = 'DEPOSIT') => cashioClient.query({
    query: gql`
      query ordersByDates($startDate: String!, $endDate: String!, $status: String!, $orderType: String!) {
        ordersByDates(startDate: $startDate, endDate: $endDate, status: $status, order_type: $orderType) {
          id
          amount
          user_id
          created_at
        }
      }
    `,
    variables: {
      startDate, endDate, status, orderType,
    },
  }),
}

export default requests
