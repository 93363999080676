<template>
  <div v-if="loading === false">
    <b-row>
      <b-col>
        <b-row>
          <b-col
            lg="3"
            md="12"
          >
            <b-card>
              Data Inicial:
              <BFormDatepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="from"
                :max="to"
                today-button
                reset-button
                close-button
                @input="adjustTime"
              />
            </b-card>
          </b-col>

          <b-col
            lg="3"
            md="12"
          >
            <b-card>
              Data Final:
              <BFormDatepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="to"
                :min="from"
                today-button
                reset-button
                close-button
                @input="adjustTime"
              />
            </b-card>
          </b-col>
          <b-col
            lg="3"
            md="12"
            class="d-flex"
          >
            <b-card class="text-center">
              <h4>
                Período de comparação
              </h4>
              <span>
                {{ comparisonDate }}
              </span>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <RegistrationStats
          v-if="userData"
          card-header-title="CPF Cadastrados"
          :card-header-value="userData.registeredCPFs"
          :card-header-percentage="userData.percentageComparison.registeredCPFs"
          card-footer-title="Usuários Ativos"
          :card-footer-value="activeUsers.activeUsers"
          :card-footer-percentage="activeUsers.activeUsersComparison"
        />
      </b-col>
      <b-col>
        <RegistrationStats
          card-header-title="Cash In"
          :card-header-value="formattedCurrency(depositsTransactionsData.transactionCounts)"
          :card-header-percentage="depositsTransactionsData.percentageComparison.transactionCounts"
          card-footer-title="Usuários"
          :card-footer-value="depositsTransactionsData.userPerTransactionLength"
          :card-footer-percentage="depositsTransactionsData.percentageComparison.userPerTransactionLength"
        />
      </b-col>
      <b-col>
        <RegistrationStats
          card-header-title="Cash Out"
          :card-header-value="formattedCurrency(withdrawalsTransactionsData.transactionCounts)"
          :card-header-percentage="withdrawalsTransactionsData.percentageComparison.transactionCounts"
          card-footer-title="Usuários"
          :card-footer-value="withdrawalsTransactionsData.userPerTransactionLength"
          :card-footer-percentage="withdrawalsTransactionsData.percentageComparison.userPerTransactionLength"
        />
      </b-col>
      <b-col>
        <RegistrationStats
          card-header-title="Balance | GGR"
          :card-header-value="formattedCurrency(balanceGGR.balance)"
          :card-header-percentage="balanceGGR.balanceComparison"
          card-footer-title="% de Cash Out"
          :card-footer-value="(balanceGGR.balancePercentage).toFixed(2) + '%'"
          :card-footer-percentage="balanceGGR.balancePercentageComparison"
        />
      </b-col>
      <b-col>
        <PendingStats
          :card-value="withdrawalsTransactionsData.usersLargeTransactionCount"
          :card-percentage="withdrawalsTransactionsData.percentageComparison.usersLargeTransactionCount"
        />
      </b-col>
    </b-row>
    <b-row class="">
      <b-col
        lg="8"
        md="12"
      >
        <FinancialChart
          :deposits-transactions-data="depositsTransactionsData"
          :withdrawals-transactions-data="withdrawalsTransactionsData"
          :user-data="userData"
          :active-users="activeUsers"
          :formatted-currency="formattedCurrency"
        />
      </b-col>
      <b-col
        lg="4"
        md="12"
      >
        <ConversionsFlow
          :user-data="userData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="8"
        md="12"
      >
        <CashFlow
          :deposits-transactions-data="depositsTransactionsData"
          :withdrawals-transactions-data="withdrawalsTransactionsData"
          :formatted-currency="formattedCurrency"
        />
      </b-col>
      <b-col
        lg="4"
        md="12"
      >
        <BrazilMap :users-by-state="userData.usersByState" />
        <ResultsCard
          :deposits-transactions-data="depositsTransactionsData"
          :withdrawals-transactions-data="withdrawalsTransactionsData"
          :formatted-currency="formattedCurrency"
        />
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <logo-loader-animation />
  </div>
</template>

<script>
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import RegistrationStats from '@/components/reports/RegistrationStats.vue'
import PendingStats from '@/components/reports/PendingStats.vue'
import FinancialChart from '@/components/reports/FinancialChart.vue'
import ConversionsFlow from '@/components/reports/ConversionsFlow.vue'
import BrazilMap from '@/components/reports/BrazilMap.vue'
import ResultsCard from '@/components/reports/ResultsCard.vue'
import {
  BCard, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import CashFlow from '@/components/reports/CashFlow.vue'
// eslint-disable-next-line import/no-cycle
import requests from '@/services/requests'
import dayjs from 'dayjs'
import {
  getUserData,
  getTransactionsData,
  getActiveUsers,
  getBalanceGGR,
  getDataFilteredByUserRole,
} from '../../services/reports/getData'
import { formatCurrency } from '../../utils/formatCurrency'

export default {
  components: {
    LogoLoaderAnimation,
    BRow,
    BCard,
    BCol,
    RegistrationStats,
    PendingStats,
    FinancialChart,
    ConversionsFlow,
    BrazilMap,
    BFormDatepicker,
    ResultsCard,
    CashFlow,
  },
  data() {
    return {
      currentDate: new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }).split(',')[0],
      from: `${dayjs(this.currentDate).format('YYYY-MM-DD')} 03:00:00`,
      to: `${dayjs(this.currentDate).add(1, 'day').format('YYYY-MM-DD')} 03:00:00`,
      loading: true,
      userData: {},
      depositsTransactionsData: {},
      withdrawalsTransactionsData: {},
      comparisonDate: {},
      activeUsers: {},
      balanceGGR: {},
    }
  },
  computed: {
    formattedCurrency() {
      return value => formatCurrency(value)
    },
  },

  watch: {
    from() {
      console.log(this.startDate)
      this.getApiData()
    },
    to() {
      console.log(this.startDate)
      this.getApiData()
    },

  },
  created() {
    this.getApiData()
  },
  methods: {
    getApiData() {
      this.loading = true

      const differenceInDays = 10 // default value = 30
      const fromComparison = `${dayjs(this.from).subtract(differenceInDays, 'day').format('YYYY-MM-DD')} 03:00:00`
      const toComparison = `${dayjs(this.to).subtract(differenceInDays, 'day').format('YYYY-MM-DD')} 03:00:00`
      this.comparisonDate = `${dayjs(fromComparison).format('YYYY-MM-DD')} até ${dayjs(toComparison).format('YYYY-MM-DD')}`

      const usersByDatesPromise = requests.getUsersByDates(this.from, this.to)
      const usersByDatesPromiseComparison = requests.getUsersByDates(fromComparison, toComparison)
      const depositsOrdersPromise = requests.getOrdersByDates(this.from, this.to, 'CONFIRMED', 'DEPOSIT')
      const depositsOrdersPromiseComparison = requests.getOrdersByDates(fromComparison, toComparison, 'CONFIRMED', 'DEPOSIT')
      const withdrawalsOrdersPromise = requests.getOrdersByDates(this.from, this.to, 'CONFIRMED', 'WITHDRAW')
      const withdrawalsOrdersPromiseComparison = requests.getOrdersByDates(fromComparison, toComparison, 'CONFIRMED', 'WITHDRAW')

      Promise.all([usersByDatesPromise, usersByDatesPromiseComparison, depositsOrdersPromise, depositsOrdersPromiseComparison, withdrawalsOrdersPromise, withdrawalsOrdersPromiseComparison])
        .then(responses => {
          const filteredResponses = getDataFilteredByUserRole(responses, JSON.parse(localStorage.getItem('userData')))
          const usersData = getUserData(filteredResponses.usersData, filteredResponses.usersDataComparison)
          const depositsData = getTransactionsData(filteredResponses.depositsData, filteredResponses.depositsDataComparison)
          const withdrawalsData = getTransactionsData(filteredResponses.withdrawalsData, filteredResponses.withdrawalsDataComparison)
          const activeUsers = getActiveUsers(depositsData, filteredResponses.depositsDataComparison, withdrawalsData, filteredResponses.withdrawalsDataComparison)
          const balanceGGR = getBalanceGGR(depositsData, withdrawalsData)

          this.userData = usersData
          this.depositsTransactionsData = depositsData
          this.withdrawalsTransactionsData = withdrawalsData
          this.activeUsers = activeUsers
          this.balanceGGR = balanceGGR
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    adjustTime() {
      this.from = `${dayjs(this.from).format('YYYY-MM-DD')} 03:00:00`
      this.to = `${dayjs(this.to).format('YYYY-MM-DD')} 03:00:00`
      this.startDate = `${dayjs(this.startDate).format('YYYY-MM-DD')} 03:00:00`
      this.endDate = `${dayjs(this.endDate).format('YYYY-MM-DD')} 03:00:00`
    },
  },
}
</script>

<style lang="scss">

</style>
