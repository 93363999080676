<template>
  <div
    :class="percentage > 0 ? 'text-success':'text-danger'"
  >
    <feather-icon
      :icon="percentage > 0 ? 'TrendingUpIcon' : 'TrendingDownIcon'"
      size="21"
      class="mr-1"
    />
    {{ Math.abs(percentage).toFixed(2) }}%
  </div>
</template>

<script>
export default {
  props: ['percentage'],
  data() {
    return {}
  },
}
</script>

<style lang="scss">

</style>
