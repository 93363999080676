<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <b-card
    class="stat-card"
  >
    <b-card-header>
      <b-card-title>
        Funil de conversão
      </b-card-title>
    </b-card-header>
    <ul class="funnel-class">
      <li class="d-flex justify-content mb-3">
        <div class="level">
          CPF Registrados
        </div>
        <div class="stat">
          {{ userData.registeredCPFs }}
        </div>
        <div class="percentage">
          {{ 100 }}%
        </div>
        <ComparisonPercentage :percentage="userData.percentageComparison.registeredCPFs" />
      </li>
      <li
        v-for="(deposit, index) in userData.depositCounts"
        v-if="index > 0"
        :key="index"
        class="d-flex justify-content mb-3"
      >
        <div class="level">
          {{ listOption[index] }}
        </div>
        <div class="stat">
          {{ deposit.quantity || 0 }}
        </div>
        <div class="percentage">
          <span>{{ deposit.totalPercentage || 0 }}%</span>
          <span class="percentage">{{ deposit.percentage || 0 }}%</span>
        </div>
        <ComparisonPercentage :percentage="userData.percentageComparison.depositCounts[index]" />
      </li>
    </ul>
  </b-card>
</template>
<script>
import {
  BCard, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import ComparisonPercentage from './ComparisonPercentage.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    ComparisonPercentage,
  },
  props: ['userData'],
  data() {
    return {
      listOption: ['', 'Primeiro depósito', 'Segundo depósito', 'Terceiro depósito', 'Quarto ou mais'],
      registeredCPFs: this.userData.registeredCPFs,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
  .funnel-class{
    padding-left: 0;
    li{
      justify-content: space-between;
      margin: 20px auto;
      line-height: 25px;
      .stat{
          font-size: 25px;
      }
    }
  }
  .percentage {
    display: flex;
    flex-direction: column;

    span:nth-child(2) {
    border-top: 1px solid #4D4D66;
    color: #7c7c96;
    font-size: 12px;
  }

  }
</style>
